import { Box, Button, Flex, Modal, Text, Tooltip } from "@mantine/core";
import { IconCheck, IconPlus, IconX } from "@tabler/icons";
import { useContext, useState } from "react";
import { dismissSuggested, validateMatch, validateRequest } from "../../../../../Api";
import { useAuth0 } from "@auth0/auth0-react";
import IdeaContext from "../../../IdeaContext";

import { openModal } from "@mantine/modals";
import LogoIcon from "../../../../layouts/LogoIcon";
import CommentsMenu from "../Comment/CommentsMenu";
import { showNotification } from "@mantine/notifications";
import { DeclineRequestModal } from "./DeclineRequestModal";
import SyncContext from "../../../../../context/SyncContext";
import { RequestAssignment } from "./RequestAssignment";
import ValidateRequestMatchPopUp from "./ValidateRequestMatchPopUp";
import posthog from "posthog-js";

const RequestMatchesActions = ({
  navToPerspective,
}: {
  navToPerspective: any;
}) => {
  const {
    loadEvidence,
    chosenEvidence,
    setChosenEvidence,
    ideaId,
    idea,
    reCalculatPerspectiveForRequest,
    setCardView,
    setAddEvidenceFormScreen,
  } = useContext(IdeaContext);
  const { defaultRequestPriority } = useContext(SyncContext);
  const auth0 = useAuth0();
  const [opened, setOpened] = useState(false);
  const [validateOpened, setValidateOpened] = useState(false);

  const openDeclineModal = () => {
    setOpened && setOpened(false);
    openModal({
      modalId: "decline",
      title: <LogoIcon />,
      centered: true,
      size: 600,
      children: (
        <DeclineRequestModal
          request={chosenEvidence?.request}
          idea={idea}
          matchId={chosenEvidence?._id}
          navToPerspective={navToPerspective}
          loadEvidence={loadEvidence}
        />
      ),
    });
  }; 

  const dismissMatch = (matchId: string) => {

    dismissSuggested(auth0, matchId, ideaId || "", )
      .then((res) => {
        /* navToPerspective() */
        setAddEvidenceFormScreen(false);
        setCardView(false);
        reCalculatPerspectiveForRequest({_id: matchId}, 'remove');
        showNotification({
          title: "This evidence has been declined",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });

        console.log(res);
        loadEvidence();
      })
      .then(()=> {
        setChosenEvidence(null)
        navToPerspective()
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const onValidate = () => {
    validateRequest(
      auth0,
      chosenEvidence?.request?._id,
      ideaId,
      chosenEvidence?._id
    )
      .then((res) => {
        console.log({ res });
        if (res.data.request) {
          reCalculatPerspectiveForRequest(
            { ...res.data.request, status: "validated" },
            "add"
          );
        }
        loadEvidence();
        showNotification({
          title: "Evidence added successfully",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });
      })
      .then((res) => console.log(res)
      )
      .catch((e) => console.log(e)).finally(()=> 
      {navToPerspective()
        setChosenEvidence(null)})
    ;
  };

  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{
        /* borderTop: "1px solid #D8D8DB", */ borderBottom: "1px solid #D8D8DB",
      }}
      align="center"
    >
      <Modal
        mt={100}
        ml={700}
        opened={opened}
        onClose={() => setOpened(false)}
        overlayOpacity={0}
        size="32vw"
        title={
          <Text fz={20} fw={600}>
            Reassign to another idea
          </Text>
        }
      >
        <Box h={445} pt={5}>
          <RequestAssignment
            idea={idea}
            item={chosenEvidence?.request}
            setOpened={setOpened}
            navToPerspective={navToPerspective}
            TextInputW="100%"
            BoxW="38vw"
          />
        </Box>
      </Modal>
      <Modal
        centered
        opened={validateOpened}
        onClose={() => setValidateOpened(false)}
        /* overlayOpacity={0} */
        /* size="32vw" */
        title={
          <Text fz={14} /* fw={600} */>
            The submitter of this request connected it to this Idea:
          </Text>
        }
      >
        <ValidateRequestMatchPopUp
          validate={onValidate}
          setOpened={setValidateOpened}
        />
      </Modal>
      <CommentsMenu />
      <Flex
        align="center"
        w="184px"
        mr="12px"
        sx={{ cursor: " pointer" }}
        onClick={() => setOpened(true)}
      >
        <Box>
          <IconPlus size={15} color="#5C5CEB" />
        </Box>
        <Tooltip label='Assign to another idea'>
          <Text ml="4px" color="#5C5CEB" fz={12} lineClamp={1}>
          Assign to another idea
          </Text>
        </Tooltip>
      </Flex>
      <Flex align="center">
        <Tooltip
          label="This AI suggestion is not relevant to this Idea. Don’t show it here again"
          multiline
          width={200}
          offset={12}

        >
          <Flex
            align="center"
            w="107px"
            mr="12px"
            sx={{ cursor: " pointer" }}
            
            onClick={() => {
              dismissMatch(chosenEvidence?._id);
              posthog.capture("suggested_not_relevant_clicked", chosenEvidence);
            }}
          >
            <Box>
              <IconX size={15} color="#5C5CEB" />
            </Box>

            <Text ml="4px" color="#5C5CEB" fz={12} lineClamp={1}>
              Not relevant
            </Text>
          </Flex>
        </Tooltip>

        <Box>
          <Tooltip
            label="Assign to this Idea"
            multiline
            maw={200}
            offset={12}
            position="left"
          >
            <Button
              fz="12px"
              color="indigo"
              w="75px"
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#5C5CEB",
                  border: 0,
                  fontWeight: 400,
                  padding: 0,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              onClick={
                chosenEvidence?.request?.featureId
                  ? () => setValidateOpened(true)
                  : onValidate
              }
            >
              <IconPlus size={14} />
              <Text>Assign</Text>
            </Button>
          </Tooltip>
        </Box>
      </Flex>
    </Flex>
  );
};

export default RequestMatchesActions;
