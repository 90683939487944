import { Box, Flex, Stack, Text, Checkbox, Group } from "@mantine/core";
import { useContext, useEffect, useState } from "react";



import DiscoveryContext from "../../DiscoveryContext";
import FiltersContext from "../../../ideas/filters/context/FiltersContext";
import { findKeyByPerspectiveDataType } from "../../../ideaNiv/NewIdeaView";
import { SingleSideCheckboxDiscovery } from "./SingleSideCheckboxDiscovery";

const PerspectiveListDiscovery = () => {

  const { activeFilters, setActiveFilters } = useContext(FiltersContext)
  const { perspectiveData, type  } = useContext(DiscoveryContext);

  const [allEvidenceChecked, setAllEvidenceChecked] = useState(false);
  const dataArr = perspectiveData[type]?.dataArr;
  const requestsCount = perspectiveData[type]?.requestsCount;
  const matchesCount = perspectiveData[type]?.matchesCount;
   
  const { key } = findKeyByPerspectiveDataType(type);
  console.log({ type, key })
  const handleCheckboxChange = (selectedArray: string[]) => {
    setActiveFilters((prevFilters) => {
      const index = prevFilters.findIndex((filter) => filter.parent === key);
      if (index !== -1) {
        // Update the existing filter
        const updatedFilters = [...prevFilters];
        updatedFilters[index] = {
          ...updatedFilters[index],
          selected: selectedArray,
        };
        return updatedFilters;
      } else {
        // Add a new filter if it does not exist (optional based on your logic)
        return [
          ...prevFilters,
          {
            operator: "", // Set the default operator if necessary
            parent: key,
            type: "checkbox", // Assuming type is 'checkbox'; adjust if necessary
            selected: selectedArray,
          },
        ];
      }
    });
  };

  const handleDisplayAll = (click :boolean) => {

    const allValues = dataArr?.map((a: any)=> a = a.value.toLowerCase())

    setActiveFilters((prevFilters) => {
      const index = prevFilters.findIndex((filter) => filter.parent === key);
      if (index !== -1) {
        // Update the existing filter
        const updatedFilters = [...prevFilters];
        updatedFilters[index] = {
          ...updatedFilters[index],
          selected: click ? allValues : [],
        };
        return updatedFilters;
      } else {
        // Add a new filter if it does not exist (optional based on your logic)
        return [
          ...prevFilters,
          {
            operator: "", // Set the default operator if necessary
            parent: key,
            type: "checkbox", // Assuming type is 'checkbox'; adjust if necessary
            selected: click ? allValues : [],
          },
        ];
      }
    });
  };

  const currentValues = activeFilters &&
    activeFilters.length > 0 && activeFilters.find((f) => f.parent === key)?.selected || [];


  function arraysContainSameValues(arr1: string[], arr2: string[]): boolean {
    if (arr1.length !== arr2.length) {
      return false;
    }
  
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
  
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }

  useEffect(() => {
    const perspectiveArr = perspectiveData[type]?.dataArr;

    if(perspectiveArr && perspectiveArr.length > 0){
      const allValues = perspectiveArr?.map((a: any)=> a = a.value.toLowerCase())
      const activePerspectiveFilterValues = activeFilters?.find((f) => f.parent === key)?.selected || [];
      activeFilters && activeFilters?.find((f) => f.parent === key)?.selected || [];
      const allValuesSelected = arraysContainSameValues(allValues, activePerspectiveFilterValues)
      if(allValuesSelected){
        setAllEvidenceChecked(true)
      }
      else if(!allValuesSelected){
        setAllEvidenceChecked(false)
      }
    }

  }, [perspectiveData, activeFilters, key, type]);

  return (
    <Box mt="23px" w='100%'>
      <Group mb={24} position="apart" w='100%'>
        <Group>
          <Checkbox
            checked={allEvidenceChecked}
            onChange={(event) => {
              setAllEvidenceChecked(event.currentTarget.checked);
              handleDisplayAll(event.currentTarget.checked);
            }}
            color="indigo"
            label={
              <Text fz="12px" lineClamp={1}>
                {" "}
                Display all evidence
              </Text>
            }
          />
        </Group>

        <Flex>
          <Flex ml="10px">
            <Text mr="4px"  color="#888D9B" fz="12px" fw={600}>
                Pending
            </Text>
            <Text color="#9F9AF8" fz="12px" fw={600}>
              {matchesCount || 0}
            </Text>
          </Flex>
        </Flex>
      </Group>
      <Checkbox.Group value={currentValues} onChange={handleCheckboxChange} w='100%'>
        {dataArr &&
          dataArr.length > 0 &&
          dataArr.map((item: any, index: number) =>
            (type == "Business Sources" ) ? (
              item?.display && (
                <SingleSideCheckboxDiscovery
                  key={index}
                  item={item}
                  pendingCount={matchesCount}
                  validatedCount={requestsCount}
                />
              )
            ) : 
              (type !== "Business Sources" ) ? (

                <SingleSideCheckboxDiscovery
                  key={index}
                  item={item}
                  pendingCount={matchesCount}
                  validatedCount={requestsCount}
                />
              ) :
                (item.matchesCount > 0)&& (
                  <SingleSideCheckboxDiscovery
                    key={index}
                    item={item}
                    pendingCount={matchesCount}
                    validatedCount={requestsCount}
                  />))}
      </Checkbox.Group>
    </Box>
  );
};

export default PerspectiveListDiscovery
