// Helper function to get nested field values
export const getNestedFieldValue = (obj: any, fieldPath: string) => {
  // Replace "chatCompany" with "company" in the fieldPath
  const modifiedFieldPath = fieldPath.replace("chatCompany", "company");

  const fieldParts = modifiedFieldPath.split(".");

  // Iterate over field parts and return the nested value or null if not found
  return fieldParts.reduce((o, part) => (o ? o[part] : null), obj);
};

// Helper function to check if the condition is met
const checkCondition = (request: Request, condition: Record<string, any>) => {
  if (!condition || Object.keys(condition).length === 0) return true;

  for (const [fieldPath, expectedValue] of Object.entries(condition)) {
    const modifiedFieldPath = fieldPath.replace("chatCompany", "company");
    const fieldValue = getNestedFieldValue(request, modifiedFieldPath);

    // If the field value doesn't match the expected value, return false
    if (fieldValue !== expectedValue) {
      return false;
    }
  }
  return true;
};


export const calculateImpactResults = (
  validated: Request[], 
  pending: Request[], 
  impactAggregationProperties: any[]
) => {
  const impactResults: Record<string, any> = {};
  
  // Loop through each property in impactAggregationProperties
  impactAggregationProperties.forEach((property: any) => {
    const { fieldPath, propertyName, impact, operation = 'sum' } = property;
  
    let total: any = 0;
    let validatedTotal: any = 0;
  
    // Initialize sets to collect company IDs
    const pendingCompanyIds: Set<string> = new Set();
    const validatedCompanyIds: Set<string> = new Set();
  
    // Check if we need to consolidate by company
    const consolidateByCompany = impact?.consolidateByCompany || false;
  
    // Maps to store values per company to avoid duplicate counting
    const pendingCompanyValues: Record<string, number[]> = {};
    const validatedCompanyValues: Record<string, number[]> = {};
  
    // Helper to apply the operation
    const applyOperation = (values: number[]) => {
      if (values.length === 0) return 0;
      switch (operation) {
      case 'concat':
        return values.join(', ');
      case 'sum':
        return values.reduce((acc, val) => acc + val, 0);
      case 'count':
        return values.length;
      case 'avg':
        return values.reduce((acc, val) => acc + val, 0) / values.length;
      case 'min':
        return Math.min(...values);
      case 'max':
        return Math.max(...values);
      default:
        return 0;
      }
    };
  
    // Validated requests
    validated.forEach((request) => {
      const isConditionMet = impact?.condition
        ? checkCondition(request, impact.condition)
        : true;
  
      if (isConditionMet) {
        const value = getNestedFieldValue(request, fieldPath);
        const companyId = getNestedFieldValue(request, "company._id");
  
        if (value && companyId) {
          if (consolidateByCompany) {
            if (!validatedCompanyValues[companyId]) {
              validatedCompanyValues[companyId] = [];
            }
            validatedCompanyValues[companyId].push(value);
          } else {
            validatedTotal += value;
          }
          validatedCompanyIds.add(companyId);
        }
      }
    });
  
    // Pending requests
    pending.forEach((request) => {
      const isConditionMet = impact?.condition
        ? checkCondition(request, impact.condition)
        : true;
  
      if (isConditionMet) {
        const value = getNestedFieldValue(request, fieldPath);
        const companyId = getNestedFieldValue(request, "company._id");
  
        if (value && companyId) {
          if (consolidateByCompany) {
            if (!pendingCompanyValues[companyId]) {
              pendingCompanyValues[companyId] = [];
            }
            pendingCompanyValues[companyId].push(value);
          } else {
            total += value;
          }
          pendingCompanyIds.add(companyId);
        }
      }
    });
  
    if (consolidateByCompany) {
      // Apply operation on company-based values
      validatedTotal = applyOperation(
        Object.values(validatedCompanyValues).flat()
      );
      total = applyOperation(Object.values(pendingCompanyValues).flat());
    }
  
    // Store the result in impactResults
    impactResults[propertyName] = {
      total,
      validatedTotal,
      customerCount: pendingCompanyIds.size, // Count distinct companies in pending requests
      validatedCustomerCount: validatedCompanyIds.size, // Count distinct companies in validated requests
    };
  });
  
  return impactResults;
};