import {
  Box,
  Flex,
  Menu,
  ScrollArea,
  Tabs,
  Text,
  Tooltip,
} from "@mantine/core";
import { useContext, useEffect, useState } from "react";
import SyncContext from "../../../../context/SyncContext";
import IdeaContext from "../../IdeaContext";
import BagelAutocomplete from "../../../general/BagelAutocomplete";
import { useAuth0 } from "@auth0/auth0-react";
import { saveMatch, saveRequest } from "../../../../Api";
import { showNotification } from "@mantine/notifications";
import { IconCheck } from "@tabler/icons";
import { capitalizeFirstLetter } from "../../../../utils/capitalizeFirstLetter";
import ReactTimeAgo from "react-time-ago";
import React from "react";
import { formatNumberValue } from "../../Funcs";

const CardInfo = ({ item }: { item: any }) => {
  const auth0 = useAuth0();
  const {
    components,
    evidenceDomain,
    domains,
    allComponents,
    owners,
    opportunityAdditionalInfo,
    accountAdditionalInfo,
  } = useContext(SyncContext);

  const { idea } = useContext(IdeaContext);
  const [activeTab, setActiveTab] = useState<string | null>("1");
  const [domain, setDomain] = useState<any>(
    evidenceDomain ? item?.componentObj?.domain : null
  );
  const [componentId, setComponentId] = useState(item?.componentObj?._id);
  const [productAreaName, setProductAreaName] = useState(
    item?.componentObj?.name
  );
  const [componentsByDomain, setComponentsByDomain] = useState<any[]>(
    evidenceDomain ? [] : components
  );
  const [owner, setOwner] = useState(item?.owner);
  const [ownerName, setOwnerName] = useState(item?.owner?.name);
  const domainsNames = domains.map((d: any) => d.name);
  const isMatch = item?.status === "match";
  const company = item?.company;
  const companyName = company?.name;
  const arr = company?.arr;
  const accountType = company?.type;
  const plan = company?.plan;
  const tier = company?.tier;
  const cxItem = item?.chat;
  const isOpportunity = cxItem?.itemType === "opportunity";
  const amount = cxItem?.amount ?? null;
  const oppurtunityType = cxItem?.opportunityType
    ? cxItem?.opportunityType
    : cxItem?.opportunityType ?? null;
  const stageName = cxItem?.stageName;
  const contact = item?.contact || item?.internalContact;
  const contactName = contact?.name || "Unknown user";
  const contactEmail = contact?.email;
  const contactPosition = contact?.title;
  const contactRole = contact?.role;
  const isCompanyExists = company?.value !== "N/A";

  const existingAdditionalFieldsAccount = accountAdditionalInfo
    ? Object.keys(accountAdditionalInfo).filter((key) => company?.[key])
    : [];
  const existingAdditionalFieldsOpportunity = opportunityAdditionalInfo
    ? Object.keys(opportunityAdditionalInfo).filter((key) => company?.[key])
    : [];

  const onSelectSave = (type: string, value: string) => {
    let updatedValue = value;

    if (type === "componentId") {
      const found = allComponents.find((a: any) => a._id === value);
      updatedValue = found?.name;
    }

    /* if(isMatch && type === 'componentId'){
      const match = matches.find((m: any)=> m._id === item?._id)
      const index = matches.findIndex((m: any)=> m._id === item?._id)
      match.componentId = value
      const copy2 = [...matches]
      match.suggestedComponent = false
      const spliced = copy2.toSpliced(index,1,match)
      setMatches(spliced) 
    } */
    console.log({
      onSelectSave: {
        type,
        value,
      },
    });
    if (item) {
      const itemObj = {
        id: item?._id,
        [type]: value,
      };

      if (isMatch) {
        saveMatch(itemObj, auth0).then((res) => {
          console.log({ editOwner: res, itemObj });

          showNotification({
            title: "Match updated",
            color: "teal",
            message: `Match ${type} updated successfully`,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
        });
      } else {
        console.log({ item, itemObj });
        saveRequest(itemObj, auth0)
          .then((response) => {
            console.log(response);

            showNotification({
              title: `${
                type === "componentId"
                  ? "Product area"
                  : type === "priority"
                    ? "Priority"
                    : "Domain"
              } updated`,
              color: "teal",
              message: `${
                type === "componentId"
                  ? "Product area"
                  : type === "priority"
                    ? "Priority"
                    : "Domain"
              } updated to "${updatedValue}" successfully`,
              icon: <IconCheck size="1.1rem" />,
              autoClose: 1200
            });
          })
          .catch((e: any) => console.log(e));
      }
    }
  };

  const updateProdudtArea = (productAreaName: string, componentId: string) => {
    setProductAreaName(productAreaName);
    setComponentId(componentId);
    /* setSuggested(false) */
  };

  const copy = [...allComponents];
  const allComponentsIds = copy.map((c: any) => (c = c._id));
  const filterComponentByDomain = (domainValue: string) => {
    const copy = [...components];
    const arr: any[] = copy.filter((s: any) => s.domain === domainValue) || [];
    setComponentsByDomain(arr);
    const filtered =
      allComponents.filter((s: any) => s.domain === domainValue) || [];
    const ids = filtered.map((a: any) => a._id);
    if (!ids.includes(componentId)) {
      /* setSuggestedComponent(false)
      setInitialValueSuggested(false) */
      setProductAreaName(null);
      setComponentId(null);
    }
  };

  const editOwner = (ownerObj: any, notification?: boolean) => {
    const itemObj = {
      id: item?._id,
      ownerId: ownerObj.id,
    };

    if (isMatch) {
      saveMatch(itemObj, auth0).then((res: any) => {
        if (notification) {
          showNotification({
            title: "Owner updated",
            color: "teal",
            message: `Match owner updated successfully`,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
        }
      });
    } else {
      saveRequest(itemObj, auth0).then((res) => {
        if (notification) {
          showNotification({
            title: "Owner updated",
            color: "teal",
            message: `Request owner updated to "${ownerObj?.name}" successfully`,
            icon: <IconCheck size="1.1rem" />,
            autoClose: 1200
          });
        }
      });
    }
  };

  const handleSelect = (item: any) => {
    console.log("handleSelect", { item });
    setProductAreaName(item.name);
    updateProdudtArea(item.name, item._id);
    /*Phase 2?  setSuggestedComponent(false);
    setMandatoryProductArea(false); */
    onSelectSave("componentId", item._id);
  };

  useEffect(() => {
    setOwnerName(item?.owner?.name);
    setOwner(item?.owner);

    if (!productAreaName || productAreaName === "Unassigned") {
      if (item?.origin === "salesforce" && idea?.componentId) {
        setProductAreaName(idea?.componentObj?.name);
        /* idea?.componentId && onSelectSave("componentId", idea?.componentId); */
        evidenceDomain && filterComponentByDomain(idea?.componentObj?.domain);
      }
    }
    if (evidenceDomain && allComponentsIds.includes(item?.componentObj?._id)) {
      filterComponentByDomain(item?.componentObj?.domain);
    }
    if (
      evidenceDomain &&
      domain &&
      !allComponentsIds.includes(item?.componentObj?._id)
    ) {
      filterComponentByDomain(domain);
    } else {
      setComponentId(item?.componentObj?._id);
      setProductAreaName(item?.componentObj?.name);
    }
  }, [item]);

  return (
    <Tabs value={activeTab} onTabChange={setActiveTab}>
      <Tabs.List>
        <Tabs.Tab w="70px" value="1">
          <Text
            ta="left"
            fz={12}
            fw={600}
            color={activeTab === "1" ? "#212529" : "#5C5CEB"}
          >
            Details
          </Text>
        </Tabs.Tab>
        {isCompanyExists && (
          <Tabs.Tab w="70px" value="2">
            <Text
              fz={12}
              fw={600}
              color={activeTab === "2" ? "#212529" : "#5C5CEB"}
            >
              Account
            </Text>
          </Tabs.Tab>
        )}
        {contact && (
          <Tabs.Tab w="70px" value="3">
            <Text
              fz={12}
              fw={600}
              color={activeTab === "3" ? "#212529" : "#5C5CEB"}
            >
              Contact
            </Text>
          </Tabs.Tab>
        )}
        {isOpportunity && (
          <Tabs.Tab w="70px" value="4">
            <Text
              fz={12}
              fw={600}
              color={activeTab === "4" ? "#212529" : "#5C5CEB"}
            >
              Opportunity
            </Text>
          </Tabs.Tab>
        )}
      </Tabs.List>

      {/* Details */}

      <Tabs.Panel value="1">
        {/* <ScrollArea h={176} mah={176}> */}

        <Box mt={12}>
          {/* Domain */}

          <Flex h={32} align="center">
            <Text fz={12} h={16} w={120} fw={400}>
              {" "}
              Domain
            </Text>
            <Menu>
              <Menu.Target>
                <Tooltip label="Select domain">
                  <Flex>
                    {/* <Text mt="4px" w="5px"></Text> */}
                    <Flex
                      h="32px"
                      /* bg="#F8F9FB" */
                      /* mx={5}
                      px="sm" */
                      py={0}
                      /* justify="center" */
                      sx={{ cursor: "pointer", borderRadius: "5px" }}
                      align="center"
                    >
                      <Text /* mr={4} */ pt="4px" fz="14px"></Text>
                      <Text fz={12} h={16} w={120} fw={400}>
                        {domain || "Unassigned"}
                      </Text>
                    </Flex>
                  </Flex>
                </Tooltip>
              </Menu.Target>

              <Menu.Dropdown>
                {domainsNames.map((domainItem: string) => (
                  <Menu.Item
                    w="180px"
                    key={domainItem}
                    onClick={() => {
                      /* onSelectSave("domain", item); */
                      setDomain(domainItem);
                      filterComponentByDomain(domainItem);
                    }}
                  >
                    <Text fz={12} h={16} w={120} fw={400}>
                      {domainItem || "Unassigned"}
                    </Text>
                  </Menu.Item>
                ))}
              </Menu.Dropdown>
            </Menu>
          </Flex>

          {/* Product area */}

          <Flex h={32} align="center">
            <Text fz={12} h={16} w={120}>
              {" "}
              Product area
            </Text>
            <BagelAutocomplete
              items={
                evidenceDomain
                  ? [...components].filter((s: any) => s.domain == domain)
                  : components
              }
              selected={productAreaName}
              onSelect={(item) => {
                setProductAreaName(item?.name);
                handleSelect(item);
              }}
              disabled={!domain && evidenceDomain}
            >
              <Tooltip
                maw={200}
                multiline={true}
                label={
                  isMatch &&
                  productAreaName &&
                  productAreaName !== idea.component
                    ? " This product area was set manually by a Bagel user It does not match the Product Area of the Idea"
                    : !domain && evidenceDomain
                      ? "Select domain first"
                      : "Select product area"
                }
              >
                <Menu.Target>
                  <Flex
                    h="32px"
                    fz={12} /* h={16} */
                    /* w={120} */
                    /* mx={5} */
                    /* px="sm" */
                    py={0}
                    /* justify="center" */
                    sx={{
                      cursor: "pointer",
                      /* borderRadius: "5px", */
                      opacity: !domain && evidenceDomain ? 0.5 : 1,
                    }}
                    align="center"
                  >
                    <Text /* mr={4} */ pt="4px" fz="12px"></Text>
                    <Text
                      fz="12px"
                      color={
                        isMatch &&
                        productAreaName &&
                        productAreaName !== idea.component
                          ? "red"
                          : "black"
                      }
                    >
                      {productAreaName || "Unassigned"}
                    </Text>
                  </Flex>
                </Menu.Target>
              </Tooltip>
            </BagelAutocomplete>
          </Flex>

          {/* Owner */}

          <Flex h={32} align="center">
            <Text fz={12} h={16} w={120}>
              Owner
            </Text>

            {/*  {(ownerName && item?.source !== "suggestion") || !item?.source ? ( */}
            <BagelAutocomplete
              items={owners.map((member: any) => ({
                name: member.name,
                id: member._id,
              }))}
              selected={ownerName}
              onSelect={(ownerItem: any) => {
                setOwnerName(ownerItem?.name);

                /* updateOwner(item); */
                setOwner(ownerItem);
                editOwner(ownerItem, true);
              }}
            >
              <Tooltip label="Select owner">
                <Flex
                  h="32px"
                  /* mx={5}
                    px="sm" */
                  py={0}
                  bg="#F8F9FB"
                  align="center"
                  sx={{ cursor: "pointer", borderRadius: "5px" }}
                >
                  <Text fz="12px" color="black">
                    {capitalizeFirstLetter(ownerName) || "Unassigned"}
                  </Text>
                </Flex>
              </Tooltip>
            </BagelAutocomplete>
            {/* ) : (
            
               
              <Text fz="12px">
                    Unassigned
              </Text>
          
            
            )} */}
          </Flex>
        </Box>
        {/* </ScrollArea> */}
      </Tabs.Panel>

      {/* Account */}

      {isCompanyExists && (
        <Tabs.Panel value="2">
          <ScrollArea h={136} mah={136}>
            <Box mt={12}>
              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Account
                </Text>
                <Text fz={12}>{companyName || "N/A"}</Text>
              </Flex>

              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  ARR
                </Text>
                <Text fz={12}>{arr ? formatNumberValue(arr): 'N/A'}</Text>
              </Flex>

              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Type
                </Text>
                <Text fz={12}>{accountType || "N/A"}</Text>
              </Flex>

              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Plan
                </Text>
                <Tooltip label={plan} disabled={!plan} multiline maw={250}>
                  <Text fz={12} lineClamp={1} w={200}>
                    {plan || "N/A"}
                  </Text>
                </Tooltip>
              </Flex>

              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Tier
                </Text>
                <Text fz={12}>{tier || "N/A"}</Text>
              </Flex>
              {existingAdditionalFieldsAccount.map((key: any) => {
                const field = accountAdditionalInfo[key];
                return (
                  <React.Fragment key={key}>
                    <Flex h={32} align="center">
                      <Text fz={12} h={16} w={120} lineClamp={1}>
                        {field?.label}:
                      </Text>
                      <Text fz={12} lineClamp={1}>{company[key] || "N/A"}</Text>
                    </Flex>
                  </React.Fragment>
                );
              })}
            </Box>
          </ScrollArea>
        </Tabs.Panel>
      )}

      {/* Contact */}
      {contact && (
        <Tabs.Panel value="3">
          <ScrollArea h={176} mah={176}>
            <Box mt={12}>
              {contactName && (
                <Flex h={32} align="center">
                  <Text fz={12} h={16} w={120}>
                    Name
                  </Text>
                  <Text fz={12}>{contactName || "N/A"}</Text>
                </Flex>
              )}

              {contactEmail && (
                <Flex h={32} align="center">
                  <Text fz={12} h={16} w={120}>
                    Email
                  </Text>
                  <Text fz={12}>{contactEmail || "N/A"}</Text>
                </Flex>
              )}

              {contactPosition && (
                <Flex h={32} align="center">
                  <Text fz={12} h={16} w={120}>
                    Position
                  </Text>
                  <Text fz={12}>{contactPosition || "N/A"}</Text>
                </Flex>
              )}

              {contactRole && (
                <Flex h={32} align="center">
                  <Text fz={12} h={16} w={120}>
                    Role
                  </Text>

                  <Text fz={12} lineClamp={1} w={200}>
                    {contactRole || "N/A"}
                  </Text>
                </Flex>
              )}
            </Box>
          </ScrollArea>
        </Tabs.Panel>
      )}

      {/* opportunity */}

      {isOpportunity && (
        <Tabs.Panel value="4">
          <ScrollArea h={140} mah={140}>
            <Box mt={12}>
              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Amount
                </Text>
                <Text fz={12}>
                  {amount ? formatNumberValue(amount): 'N/A'} 
                
                </Text>
              </Flex>

              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Stage
                </Text>
                <Text fz={12}>{stageName || "N/A"}</Text>
              </Flex>

              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Opportunity type
                </Text>

                <Text fz={12} lineClamp={1} w={200}>
                  {oppurtunityType || "N/A"}
                </Text>
              </Flex>

              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  Probability
                </Text>
                <Text fz={12}>{cxItem?.probability || "N/A"}</Text>
              </Flex>

              <Flex h={32} align="center">
                <Text fz={12} h={16} w={120}>
                  close date
                </Text>

                <Text fz={12} lineClamp={1} w={200}>
                  {cxItem?.closedAt ? (
                    <ReactTimeAgo date={new Date(cxItem?.closedAt)} />
                  ) : (
                    "N/A"
                  )}
                </Text>
              </Flex>
              {existingAdditionalFieldsOpportunity.map((key: any) => {
                const field = opportunityAdditionalInfo[key];
                return (
                  <React.Fragment key={key}>
                    <Flex h={32} align="center">
                      <Text fz={12} h={16} w={120} lineClamp={1}>
                        {field?.label}:
                      </Text>
                      <Text fz={12} lineClamp={1}>{company[key] || "N/A"}</Text>
                    </Flex>
                  </React.Fragment>
                );
              })}
            </Box>
          </ScrollArea>
        </Tabs.Panel>
      )}
    </Tabs>
  );
};

export default CardInfo;
