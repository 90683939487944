
// Sorting function

export const sortEvidenceBySortValue = (value: string, requests: any[], descending: boolean)=> {

  const requestsCopy = [...requests]

  if(value === ''){
    const sortedByInitialSort = requestsCopy.sort((a: any, b: any) => a?.sortInit - b?.sortInit)
    
    return sortedByInitialSort 
  }
   
  else if(value === 'updatedAt'){

    const mappedUpdatedAt = requestsCopy.map((a: any) => {
      if (!a?.updatedAt && a?.textItem && a?.textItem?.updatedAt) {
        a.updatedAt = a?.textItem?.updatedAt;
      }
      return a; 
    }) 

    const mapped = mappedUpdatedAt.map((a: any) => {
      a.updatedAt = new Date(a.updatedAt)
      return a; 
    });

    let sortedByDate = mapped.sort((a: any, b: any) => a?.updatedAt?.getTime() - b?.updatedAt?.getTime())

    if (descending){
      sortedByDate = sortedByDate.reverse();
    }
    
    return sortedByDate 
  }

  else if(value === 'businessName'){

    let sortedByBusinessName = requestsCopy.sort((a: any, b: any) => {
      if (a['businessName'] < b['businessName']) return -1;
      if (a['businessName'] > b['businessName']) return 1;
      return 0;
    });

    if(!descending){
      sortedByBusinessName = sortedByBusinessName.reverse();
    }
    
    return sortedByBusinessName
  }
}

// Filters functions

export const filterEvidenceByBusinessNameArr = (requests: any[], businessNames: string[]) => {
  const lowercaseBusinessNames = businessNames.map(name => name.toLowerCase());

  const filteredRequests = requests.filter(r => {
    const businessNameLower = (r?.businessName || '').toLowerCase();
    return lowercaseBusinessNames.includes(businessNameLower);
  });

  return filteredRequests || [];
};


export const filterSuggested = (requests: any[]) => {
  const filtered: any[] = requests.filter(d => d.status.toLowerCase() === 'validated');

  return filtered || [];
};


export const filterEvidenceCustomerTier = (requests: any[], tiers: string[]) => {
  const lowercaseTiers = tiers.map(tier => tier.toLowerCase());

  const filteredRequests = requests.filter(r => {
    const tierLower = (r?.company?.tier || '').toLowerCase();
    return lowercaseTiers.includes(tierLower);
  });

  return filteredRequests || [];
};


export const filterEvidenceCustomerPlan = (requests: any[], plans: string[]) => {
  const lowercasePlans = plans.map(plan => plan.toLowerCase());

  const filteredRequests = requests.filter(r => {
    const planLower = (r?.company?.plan || '').toLowerCase();
    return lowercasePlans.includes(planLower);
  });

  return filteredRequests || [];
};

export const filterEvidenceCustomerAccountType = (requests: any[], accountTypes: string[]) => {
  const lowercaseAccountTypes = accountTypes.map(type => type.toLowerCase());

  const filteredRequests = requests.filter(r => {
    const accountTypeLower = (r?.company?.type || '').toLowerCase();
    return lowercaseAccountTypes.includes(accountTypeLower);
  });

  return filteredRequests || [];
};

// Numbers display functions 

export function formatNumberValue(num: number): string {
  // Format the number with up to 2 decimal places
  const formatted = num.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 2 });
  
  // Remove trailing zeros from the decimal part

  const withDollarSign = `$${formatted.replace(/(\.\d*?[1-9])0+$/, '$1').replace(/\.$/, '')}`

  return withDollarSign;
}

export function formatMonetaryValue(num: number) {
  if (num < 10000) {
    return formatNumberValue(num);
  } else if (num < 1000000) {
    return `$${Math.round(num / 1000)}k`;
  } else {
    return `$${(num / 1000000).toFixed(2)}M`;
  }
}

export function formatBigMonetaryValue(num: number) {
  if (num <= 99000000) {
    return formatMonetaryValue(num);
  } else if (num > 99000000){
    return `$${Math.round(num / 1000000)}M..`;
  }
}


type FormatSettings = {
  threshold: number; // The threshold value to switch formatting
  suffix: string;    // The suffix to add for this threshold
  divisor?: number;   // Optional: Divides the number for formatting (e.g., divide by 1000 for 'k')
  round?: boolean;    // Optional: Whether to round the value at this threshold
};

export function formatMonetary(
  num: number, 
  formatSettings: FormatSettings[] = [
    { threshold: 10000, suffix: '', round: false },
    { threshold: 1000000, suffix: 'k', divisor: 1000, round: true },
    { threshold: 99000000, suffix: 'M', divisor: 1000000, round: false },
  ],
  prefix = '$', 
  suffix = ''
) {
  let formattedNum = num;
  let selectedSuffix = suffix;

  for (const setting of formatSettings) {
    if (num < setting.threshold) {
      if (setting.divisor) {
        formattedNum = num / setting.divisor;
      }
      if (setting.round) {
        formattedNum = Math.round(formattedNum);
      }
      selectedSuffix = setting.suffix;
      break;
    }
  }

  return `${prefix}${formattedNum?.toFixed(2)}${selectedSuffix}${suffix}`;
}





