import { Box, Button, Flex, Text, Tooltip } from "@mantine/core";
import { IconBell, IconCheck, IconPlus, IconX } from "@tabler/icons";
import { useContext, useState } from "react";
import { showNotification } from "@mantine/notifications";
import { useAuth0 } from "@auth0/auth0-react";

import posthog from "posthog-js";
import SyncContext from "../../../../../context/SyncContext";
import IdeaContext from "../../../IdeaContext";
import { dismissSuggested, validateMatch } from "../../../../../Api";

const MatchActions = () => {
  const auth0 = useAuth0();

  const { defaultRequestPriority } = useContext(SyncContext);

  const {
    loadEvidence,
    chosenEvidence,
    ideaId,
    idea,
    reCalculatPerspectiveForRequest,
    setAddEvidenceFormScreen,
    setCardView,
    setChosenEvidence,
  } = useContext(IdeaContext);


  const [loading, setLoading] = useState(false);

  const onValidateMatch = (matchId: string, ideaId: string, textId: string) => {

    const request: any = {};
    request.title = chosenEvidence?.textItem?.title;
    request.businessName = chosenEvidence?.textItem?.businessName;
    request.textId = textId;
    request.ownerId = idea?.ownerId;
    request.componentId = idea?.componentId;
    request.type = chosenEvidence?.chat?.itemType;  
    request.priority= defaultRequestPriority, 
    request.source = 'suggestion',
    validateMatch(auth0, matchId, ideaId, request, textId)
      .then((res) => {
          
        console.log("validateMatch",{res});
  
        setChosenEvidence({});

        loadEvidence();
          
        if(res.request){
          reCalculatPerspectiveForRequest({...res.request, status:"validated"}, 'update');
        }
        setCardView(false);
        setAddEvidenceFormScreen(false);
        /* navToPerspective() */
        showNotification({
          title: "Evidence added successfully",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200, 
        });
      })
      .then(()=>setChosenEvidence(null))
      .catch((e) => {
        showNotification({
          title: "Problem occured!",
          color: "red",
          message: "Problem occured while adding evidence...",
          icon: <IconX size="1.1rem" />,
          autoClose: 1200
        });
        console.log(e);
      });
    
  };

  const dismissMatch = (matchId: string) => {

    dismissSuggested(auth0, matchId, ideaId || "", chosenEvidence.textId)
      .then((res) => {
        /* navToPerspective() */
        setAddEvidenceFormScreen(false);
        setCardView(false);
        reCalculatPerspectiveForRequest({_id: matchId}, 'remove');
        showNotification({
          title: "This evidence has been declined",
          color: "teal",
          message: ``,
          icon: <IconCheck size="1.1rem" />,
          autoClose: 1200
        });

        console.log(res);
        loadEvidence();
      })
      .then(()=>setChosenEvidence(null))
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <Flex
      justify="space-between"
      px="35px"
      pb="20px"
      h="64px"
      sx={{ /* borderTop: "1px solid #D8D8DB", */ borderBottom: "1px solid #D8D8DB" }}
      align="center"
    >
      <Flex align="center" w="107px" mr="12px" sx={{ cursor: "default" }}>
        <Box>
          <IconBell size={15} color="#D8D8DB" />
        </Box>

        <Text ml="4px" color="#D8D8DB" fz={12} lineClamp={1}>
          Activity
        </Text>
      </Flex>
      <Flex align="center">
        <Tooltip
          label="This AI suggestion is not relevant to this Idea. Don’t show it here again"
          multiline
          maw={200}
          offset={12}

        >
          <Flex
            align="center"
            w="107px"
            mr="12px"
            sx={{ cursor: " pointer" }}
            onClick={() => {
              dismissMatch(chosenEvidence?._id);
              posthog.capture('suggested_not_relevant_clicked', chosenEvidence)
            }}
          >
            <Box>
              <IconX size={15} color="#5C5CEB" />
            </Box>

            <Text ml="4px" color="#5C5CEB" fz={12} lineClamp={1}>
            Not relevant
            </Text>
          </Flex>
        </Tooltip>
        <Box>
          <Tooltip
            label="Assign to this Idea"
            multiline
            maw={200}
            offset={12}
            position="left"
          >
            <Button
              fz="12px"
              color="indigo"
              w="75px"
              loading={loading}
              styles={(theme) => ({
                root: {
                  height: "32px",
                  backgroundColor: "#5C5CEB",
                  border: 0,
                  fontWeight: 400,
                  padding: 0,
                  "&:hover": {
                    backgroundColor: theme.fn.darken("#5C5CEB", 0.05),
                  },
                },

                leftIcon: {
                  marginRight: 15,
                },
              })}
              onClick={() => {
                onValidateMatch(chosenEvidence?._id, ideaId || "", chosenEvidence?.textId);
                posthog.capture('suggested_assign_clicked', chosenEvidence)
              }}
            >
              <IconPlus size={14} />
              <Text>Assign</Text>
            </Button>
          </Tooltip>
        </Box>
      </Flex>
    </Flex>
  );
};

export default MatchActions;
