import React, { useContext } from "react";
import {
  Collapse,
  Group,
  ActionIcon,
  TextInput,
} from "@mantine/core";
import { IconCheck, IconX } from "@tabler/icons";
import { SplitButton } from "../../general/SplitButton";
import IdeasContext from "../IdeasContext";
import { showNotification } from "@mantine/notifications";
import FiltersMenuWrapper from "./FiltersMenuWarper";

interface FilterActionsProps {
  activeFilters: any[];
  menuFiltersObj: any;
  setActiveFilters: (filters: any[]) => void;
  selectedView: any;
  open: () => void;
  handleSaveViews: () => void;
  opened: boolean;
  viewName: string;
  setViewName: (name: string) => void;
  doClose: () => void;
}

const FilterActions: React.FC<FilterActionsProps> = ({
  activeFilters,
  menuFiltersObj,
  setActiveFilters,
  selectedView,
  open,
  handleSaveViews,
  opened,
  viewName,
  setViewName,
  doClose,
}) => {
  const { upsertView } = useContext(IdeasContext);

  return (
    <Collapse in={true}>
      <Group mx="sm" mb="sm" position="apart" noWrap align="start">
        <Group spacing={8}>
          <FiltersMenuWrapper
            menuFiltersObj={menuFiltersObj}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            selectedView={selectedView}
            MenuType="idea"
          />

          <FiltersMenuWrapper
            menuFiltersObj={menuFiltersObj}
            activeFilters={activeFilters}
            setActiveFilters={setActiveFilters}
            selectedView={selectedView}
            MenuType="evidence"
            targetSx={{
              marginLeft: 100
            }}
          />
        </Group>
        <Group spacing={4} noWrap>
          <SplitButton
            isSplit={!selectedView?.isGlobal && !opened}
            lable={
              selectedView?.isGlobal && !opened
                ? "Save as new view"
                : !selectedView?.isGlobal
                  ? "Save view"
                  : "Save"
            }
            onClick={() => {
              console.log({
                selectedView,
                elseif: !selectedView?.isGlobal && !opened,
              });
              if (selectedView?.isGlobal && !opened) {
                open();
              } else if (opened) {
                handleSaveViews();
              } else if (!selectedView?.isGlobal && !opened) {
                upsertView(
                  { _id: selectedView._id, filters: activeFilters },
                  (res) => {
                    console.log(res);
                    showNotification({
                      title: "Saved successfully!",
                      color: "teal",
                      message: "View saved successfully",
                      icon: <IconCheck size="1.1rem" />,
                      autoClose: 1200
                    });
                  }
                );
              }
            }}
            items={[
              {
                id: "1",
                onClick: () => open(),
                name: "Save as new view",
              },
            ]}
          />

          {/* New view name input */}
          {opened && (
            <>
              <TextInput
                value={viewName}
                onChange={(event) => setViewName(event.currentTarget.value)}
                placeholder="View name"
                size="xs"
              />
              <ActionIcon sx={{ height: 32 }} onClick={doClose}>
                <IconX size={14} />
              </ActionIcon>
            </>
          )}
        </Group>
      </Group>
    </Collapse>
  );
};

export default FilterActions;
