import {
  getFilteredIdeas,
  addViews,
  listMembers,
  getViews,
  saveIdeaStarred, listOwners,
} from "../../Api";
import _ from "lodash";
import { getBagelId } from "../../utils";
import { ActiveFilter } from "./filters/FiltersMenu";
import { Auth0ContextInterface, User } from "@auth0/auth0-react";

const PAGE_SIZE = 50;

interface SortStatus {
  columnAccessor?: string;
  direction?: string;
}

export const getFilteredRequests = (
  sortStatus: SortStatus,
  setLastFilterParams: React.Dispatch<React.SetStateAction<any>>,
  _page: string | null,
  search: string,
  activeFilters: ActiveFilter[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
  setIdeas: React.Dispatch<React.SetStateAction<any[]>>,
  setRequestsTotalCount: React.Dispatch<React.SetStateAction<number>>,
  setPage: React.Dispatch<React.SetStateAction<string | null>>,
  auth0: Auth0ContextInterface<User>
) => {
  const sort: any = {};
  if(sortStatus?.columnAccessor){
    sort[sortStatus?.columnAccessor] = sortStatus?.direction === "asc" ? -1 : 1;
  }

  setLastFilterParams((prevState: any) => {
    const params = {
      sort,
      limit: PAGE_SIZE,
      skip: ((Number(_page) || 1) - 1) * PAGE_SIZE,
      filters: {
        search,
        activeFilters,
      },
    };
    if (
      !(
        _.isEqual(params.sort, prevState.sort) &&
        _.isEqual(params.skip, prevState.skip) &&
        _.isEqual(params.filters?.search, prevState.filters?.search) &&
        _.isEqual(
          params.filters?.activeFilters,
          prevState.filters?.activeFilters
        )
      )
    ) {
      setLoading(true);
      getFilteredIdeas(
        {
          search,
          activeFilters,
        },
        sort,
        PAGE_SIZE,
        ((Number(_page) || 1) - 1) * PAGE_SIZE,
        auth0
      )
        .then(({ totalCount, ideas }: { totalCount: number; ideas: any[] }) => {
          setIdeas(ideas);
          if(totalCount <= PAGE_SIZE){
            setPage('1')
          }
          setRequestsTotalCount(totalCount);
        })
        .catch((e) => console.log("filterRequests e:", e))
        .finally(() => setLoading(false));
      return params;
    } else {
      return prevState;
    }
  });
};

export const starClicked = (
  _id: string,
  bool: boolean,
  setLoadingStar: React.Dispatch<React.SetStateAction<any>>,
  idea: any,
  setIdeas: React.Dispatch<React.SetStateAction<any[]>>,
  auth0: Auth0ContextInterface<User>
) => {
  setLoadingStar((prev: any) => ({
    ...prev,
    [_id]: true,
  }));
  saveIdeaStarred(idea, auth0)
    .then(() => {
      setIdeas((prevState: any) => {
        const updated = prevState.map((item: any) => {
          if (item._id == _id) {
            const userId = getBagelId(auth0.user);
            return {
              ...item,
              starred: bool ? userId : false,
            };
          } else {
            return item;
          }
        });

        return updated;
      });
    })
    .catch(console.log)
    .finally(() =>
      setLoadingStar((prev: any) => ({
        ...prev,
        [_id]: false,
      }))
    );
};

export const loadViews = (
  deleted: boolean,
  setSavedViews: React.Dispatch<React.SetStateAction<any[]>>,
  selectedView: any,
  setSelectedView: React.Dispatch<React.SetStateAction<any>>,
  auth0: Auth0ContextInterface<User>
) => {
  getViews(auth0)
    .then(({ views }: any) => {
      setSavedViews(views);
      if (!selectedView?._id || deleted) {
        setSelectedView(views[0]);
      }
    })
    .catch((e) => {
      console.log(e);
    });
};

export const loadListMembers = (
  setMenuFiltersObj: React.Dispatch<React.SetStateAction<any>>,
  auth0: Auth0ContextInterface<User>
) => {
  listOwners(auth0)
    .then((members: any) => {
      setMenuFiltersObj((prev: any) => ({
        ...prev,
        Owner: {
          ...prev.Owner,
          options: members.reduce(
            (acc: any, { _id, name }: { _id: string; name: string }) => {
              acc[_id] = name;
              return acc;
            },
            {}
          ),
        },
        Mentions: {
          ...prev.Mentions,
          options: members.reduce(
            (acc: any, { _id, name }: { _id: string; name: string }) => {
              acc[_id] = name;
              return acc;
            },
            {}
          ),
        },
      }));
    })
    .catch((e) => {
      console.log(e);
    });
};

export const upsertViews = (
  body: any,
  func: (res: any) => void,
  auth0: Auth0ContextInterface<User>,
  setSavedViews: React.Dispatch<React.SetStateAction<any[]>>, // Add this line
  selectedView: any, // Add this line
  setSelectedView: React.Dispatch<React.SetStateAction<any>>, // Add this line
) => {
  addViews(body, auth0)
    .then((res: any) => {
      func(res);
      loadViews(
        body?.deleted,
        setSavedViews,
        selectedView,
        setSelectedView,
        auth0
      );
    })
    .catch((e) => {
      console.log(e);
    });
};

export const handleSaveViews = (
  viewName: string,
  activeFilters: ActiveFilter[],
  selectedView: any,
  setSelectedView: React.Dispatch<React.SetStateAction<any>>,
  showNotification: any,
  doClose: () => void,
  auth0: Auth0ContextInterface<User>,
  setSavedViews: React.Dispatch<React.SetStateAction<any[]>>, // Add this line
) => {
  upsertViews(
    { name: viewName, filters: activeFilters, _id: selectedView._id },
    (res) => {
      setSelectedView({
        _id: res?.data?.updateOneResult?.upsertedId,
        name: viewName,
        filters: activeFilters,
      });
      showNotification({
        title: "Saved successfully!",
        color: "teal",
        message: "view saved successfully",
        autoClose: 1200
        // icon: <IconCheck size="1.1rem" />,
      });
      doClose();
    },
    auth0,
    setSavedViews, // Add this line
    selectedView, // Add this line
    setSelectedView, // Add this line
  );
};




export const searchComponents = (text: string, components: any[]) => {
  if (typeof text !== 'string') {
    return;
  }

  const lowercaseText = text.toLowerCase();
  const filteredComponents: any[] = [];

  components.forEach((parent: any) => {
    const childComponents: any[] = parent.childComponents || [];

    // Check if the parent component matches the search text
    const isParentMatch = parent.name.toLowerCase().includes(lowercaseText);
    if (isParentMatch) {
      filteredComponents.push(parent);
    }

    // Filter the child components that match the search text
    const matchedChildren = childComponents.filter((child: any) =>
      child.name.toLowerCase().includes(lowercaseText)
    );

    // If there are any matched children, add the parent with the matched children to the filteredComponents
    if (matchedChildren.length > 0) {
      const parentWithMatchedChildren = { ...parent, childComponents: matchedChildren };
      filteredComponents.push(parentWithMatchedChildren);
    }
  });

  // Remove duplicates based on the parent's id
  const filteredComponentsWithoutDuplicates = Array.from(
    new Set(filteredComponents.map((c: any) => c._id)), // Use '_id' instead of 'id'
    (id:any) => {
      return filteredComponents.find((c: any) => c._id === id); // Use '_id' instead of 'id'
    }
  );

  return filteredComponentsWithoutDuplicates;
};

// export function searchFilter(obj: any, text: string): any {
//   if (typeof text !== 'string') {
//     return;
//   }

//   text = text.toLowerCase();

//   const result: any = {};
//   const allowedKeys = ['name', 'value', 'title']; 

//   const hasAllowedKeys = allowedKeys.some(key => key in obj);

//   if (Array.isArray(obj)) { // Check if obj is an array
//     obj.forEach((item: any, index: number) => {
//       if (typeof item === 'string') { // Check if the item is a string
//         if (item.toLowerCase().includes(text)) {
//           result[index] = item;
//         }
//       }
//     });
//   } else if(typeof obj == 'object'){
//     for (const key in obj) {
//       if (hasAllowedKeys) {
//         if (allowedKeys.includes(key) && typeof obj[key] === 'string') {
//           if (obj[key].toLowerCase().includes(text)) {
//             result[key] = obj[key];
//           // console.log(`Match found - Key: ${key}, Value: ${obj[key]}`);
//           }
//         }
//       } else {
//         if (typeof obj[key] === 'string') {
//           if (obj[key].toLowerCase().includes(text)) {
//             result[key] = obj[key];
//           // console.log(`Match found - Key: ${key}, Value: ${obj[key]}`);
//           }
//         } else if (typeof obj[key] === 'object' && obj[key] !== null) {
//           const subResult = searchFilter(obj[key], text);
//           if (Object.keys(subResult).length > 0) {
//             result[key] = subResult;
//           }
//         }
//       }
//     }
//   }
  
//   console.log('Searching in object:',{text, obj, result});

//   return result;
// }

export function searchFilter( items: any[], searchText: string): any[] {
  if (typeof searchText !== 'string' || searchText === "") {
    return items; // Return all items if no search text
  }

  const lowerCaseSearchText = searchText.toLowerCase();
  const allowedKeys = ['name', 'value', 'title']; // Define which keys are searchable

  // Filter items based on allowed keys and if the value includes the search text
  const result: any = items.filter(item => {
    return allowedKeys.some(key => {
      const itemValue = item[key as any];
      return typeof itemValue === 'string' && itemValue.toLowerCase().includes(lowerCaseSearchText);
    });
  });

  console.log('Searching in object:',{searchText, items, result});
  return result;
}

type QueryParams = {
  page?: string | null;
  filters?: string | null;
  selectedView?: string | null;
  sortStatus?: string | null;
};

export const buildQueryString = (page:string, filters:any, selectedView:string, sortStatus:string): string => {
  const queryParams: QueryParams = {
    page: page || null,
    filters: filters ? JSON.stringify(filters) : null,
    selectedView: selectedView ? JSON.stringify(selectedView) : null,
    sortStatus: sortStatus ? JSON.stringify(sortStatus) : null,
  };

  const searchParams = new URLSearchParams();

  Object.entries(queryParams).forEach(([key, value]: any) => {
    if (value !== null) {
      searchParams.set(key, typeof value === 'string' ? value : encodeURIComponent(value));
    }
  });

  return searchParams.toString();
};