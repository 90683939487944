import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import TimeAgo from "javascript-time-ago";
import en from "javascript-time-ago/locale/en.json";
import { useAuth0 } from "@auth0/auth0-react";
import "./App.css";
import "./css/common.css";
import "./css/wizard.css";
import { Integrations } from "./components/settings/integrations/Integrations";
import AuthCallback from "./components/AuthCallback";
import { MainLayout } from "./components/layouts/MainLayout";
import Members from "./components/settings/Members";
import Account from "./components/settings";
import Integration from "./components/settings/integrations/Integration";
import { IntercomProps, IntercomProvider } from "react-use-intercom";
import {getBagelId, getConnectedUserRole, getIntercomInitPropsForUser} from "./utils";
import Teams from "./components/settings/Teams";
import VerifyEmail from "./VerifyEmail";
import TopRequests from "./components/reports/TopRequests/Index";
import { CompleteIntegrationAuth } from "./components/settings/CompleteIntegrationAuth";
import { IdeasWraper } from "./components/ideas";
import { SyncContextProvider } from "./context/SyncContext";
import Inbox from "./components/requestsInbox/index";
import Objects from "./components/settings/objects/ObjectsAndFields";
import Object from "./components/settings/objects/Object";
import Idea from "./components/settings/objects/Idea";
import ProductAreas from "./components/settings/ProductArea/ProductAreas";
import ImportIdeasEvidence from "./components/ImportIdeasEvidence";
import ImportItems from "./components/ImportItems";
import NewIdeaView from "./components/ideaNiv/NewIdeaView";
import { IdeaContextProvider } from "./components/ideaNiv/IdeaContext";
import ExportItems from "./components/ExportItems";
import ExportIdeas from "./components/ExportIdeas";
import { usePostHog } from "posthog-js/react";
import { FiltersContextProvider } from "./components/ideas/filters/context/FiltersContext";
import UnauthorizedMessage from "./UnauthorizedMessage";
import Discovery from "./components/Discovery/Discovery";
import { DiscoveryContextProvider } from "./components/Discovery/DiscoveryContext";

TimeAgo.addDefaultLocale(en);

function App() {
  const auth0 = useAuth0();
  const posthog = usePostHog();
  
  useEffect(() => {
    if (auth0.user) {
      const userId = getBagelId(auth0.user);

      if (posthog) {
        posthog.identify(userId, {
          ...auth0.user,
          email: auth0.user.email,
        });
      }
    }
  }, [auth0]);

  if (
    (auth0.isLoading === undefined && auth0.isAuthenticated === undefined) ||
    auth0.isLoading
  ) {
    return <div>Loading...</div>;
  }
  if (!auth0.isLoading && !auth0.isAuthenticated) {
    auth0.loginWithRedirect();
    return <div>Redirecting to login...</div>;
  } else {
    posthog?.capture("logged_in");
  }

  const forceEmailVerification =
    window.__RUNTIME_CONFIG__.REACT_APP_FORCE_VERIFY_EMAILS === "true";
  if (forceEmailVerification && auth0.user?.email_verified !== true) {
    return <VerifyEmail />;
  }

  const userRole = getConnectedUserRole(auth0);
  const bagelId = getBagelId(auth0.user);
  if (!bagelId || !["admin", "manager", "agent"].includes(userRole)) {
    return <UnauthorizedMessage />;
  }

  const intercomInitProps: IntercomProps = getIntercomInitPropsForUser(
    auth0.user
  );

  return (
    <Router>
      <IntercomProvider
        appId={window.__RUNTIME_CONFIG__.REACT_APP_INTERCOM_APP_ID}
        autoBoot={true}
        autoBootProps={intercomInitProps}
      >
        <SyncContextProvider>
          <MainLayout>
            <Routes>
              <Route path="/" element={<IdeasWraper />} />
              <Route path="/inbox" element={<Inbox />} />
              <Route path="/ideas" element={<IdeasWraper />} />
              <Route
                path="/idea/:id"
                element={
                  <IdeaContextProvider>
                    <FiltersContextProvider>
                      <NewIdeaView /> 
                    </FiltersContextProvider>
                  </IdeaContextProvider>
                }
              />
              <Route
                path="/discovery"
                element={
                  <FiltersContextProvider>
                    <DiscoveryContextProvider>
                    
                      <Discovery />
                    </DiscoveryContextProvider>

                  </FiltersContextProvider>
                }
              />
              <Route path="/reports" element={<TopRequests />}>
                <Route path="top-requests" element={<TopRequests />} />
              </Route>
              <Route path="/settings" element={<Account />} />
              <Route path="/settings/account" element={<Account />} />
              <Route path="/settings/integrations" element={<Integrations />} />
              <Route
                path="/settings/integrations/:type"
                element={<Integration />}
              />
              <Route path="/settings/teams" element={<Teams />} />
              <Route path="/settings/members" element={<Members />} />
              <Route
                path="/settings/import/ideas_evidence"
                element={<ImportIdeasEvidence />}
              />
              <Route path="/settings/import/items" element={<ImportItems />} />

              <Route path="/settings/export/ideas" element={<ExportIdeas />} />
              <Route path="/settings/export/items" element={<ExportItems />} />

              <Route path="/settings/productareas" element={<ProductAreas />} />
              <Route path="/settings/objects" element={<Objects />} />
              <Route path="/settings/objects/:slug" element={<Object />} />
              <Route
                path="/settings/objects/idea/:ideaObject"
                element={<Idea />}
              />
              <Route
                path="/newIntegration"
                element={<CompleteIntegrationAuth />}
              />
              <Route path="/postLogin" element={<AuthCallback />} />
            </Routes>
          </MainLayout>
        </SyncContextProvider>
      </IntercomProvider>
    </Router>
  );
}

export default App;
