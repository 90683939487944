import { useContext, useEffect, useState } from "react";
import {Box, Flex, Group, Text} from "@mantine/core";
import IdeaContext from "../IdeaContext";
import ImpactPieChart from "./ImpactPieChart";
import CompaniesList from "./Companies/CompaniesList";
import SyncContext from "../../../context/SyncContext";
import { calculateImpactResults, getNestedFieldValue } from "../helpers/calculateImpactResults";

interface Company {
  _id: string;
  arr?: number;
  name: string;
}

interface Chat {
  _id: string;
  itemType?: string;
  amount?: number;
}

interface Request {
  company?: Company | null;
  chat?: Chat | null;
}

const Impact: React.FC = () => {
  const { impactAggregationProperties } = useContext(SyncContext);
  const {
    displayedRequests,
    displayedMatches,
    displayedRequestsMatches,
    ideaId,
    showmatches,
    showValidated,
    showPending,
    activeInboxTab,
  } = useContext(IdeaContext);

  const [impactData, setImpactData] = useState<Record<string, any>>({});
  const [dynamicCount, setDynamicCount] = useState<number>(0);
  const [dynamicOrgIds, setDynamicOrgIds] = useState<string[]>([]);

  useEffect(() => {
    const _pendingRequests = displayedRequests.filter(
      (r: any) => r.status !== "validated"
    );
    const _validatedRequests = displayedRequests.filter(
      (r: any) => r.status === "validated"
    );

    const matchesCopy = activeInboxTab
      ? [...displayedRequestsMatches, ..._pendingRequests]
      : showmatches && !showValidated
        ? [...displayedMatches]
        : showmatches && showValidated
          ? [
            ...displayedRequestsMatches,
            ..._pendingRequests,
            ...displayedMatches,
          ]
          : [];

    const validatedCopy = showValidated ? [..._validatedRequests] : [];

    // Collect company IDs from both pending and validated requests
    const allCompanyIdsSet = new Set<string>();

    const collectCompanyIds = (requests: Request[]) => {
      requests.forEach((request) => {
        const companyId = getNestedFieldValue(request, "company._id");
        if (companyId && companyId !== 'N/A') {
          allCompanyIdsSet.add(companyId);
        }
      });
    };

    collectCompanyIds(matchesCopy);
    collectCompanyIds(validatedCopy);

    // Update state with the total count and list of company IDs
    setDynamicCount(allCompanyIdsSet.size);
    setDynamicOrgIds(Array.from(allCompanyIdsSet));

    // Proceed with calculating impact
    const impactResults = calculateImpactResults(validatedCopy, matchesCopy, impactAggregationProperties);
    setImpactData(impactResults);
  }, [
    displayedRequests,
    displayedMatches,
    displayedRequestsMatches,
    ideaId,
    showmatches,
    showValidated,
    activeInboxTab,
  ]);

  return (
    <Box px={36} py={24}>
      <Flex mb={4}>
        <Text fw={600} fz={14} color="#212529" mb={15} mr={4}>
            Impact:
        </Text>
        <CompaniesList
          dynamicCount={dynamicCount}
          dynamicOrgIds={dynamicOrgIds}
        />
      </Flex>

      <Group position="left" spacing={40}>
        {impactAggregationProperties.map((property: any) => {
          const { label, propertyName, impact } = property;
          const impactResult = impactData[propertyName] || {};

          return (
            <ImpactPieChart
              key={propertyName}
              title={[label]}
              validatedColor={
                showValidated ? "#5C5CEB" : "rgba(92, 92, 235, 0.6)"
              }
              pendingColor={
                showmatches || showPending
                  ? "rgba(92, 92, 235, 0.6)"
                  : "#5C5CEB"
              }
              monetaryValue={impactResult.total}
              validatedMonetaryValue={impactResult.validatedTotal}
              customerCount={impactResult.customerCount}
              validatedCustomerCount={impactResult.validatedCustomerCount}
              prefix={impact?.prefix}
            />
          );
        })}
      </Group>
    </Box>
  );
};

export default Impact;